import { ServiceTopology } from '@wix/thunderbolt-ssr-api'
import { IComponentsRegistrar } from '@wix/thunderbolt-components-loader'

import {
	registry,
	createRegistryAPI,
	getComponentsLibraries,
	IThunderboltRegistryAPI,
	IInfoComponentModel,
} from '@wix/editor-elements-registry/thunderbolt'
import { getGlobalRegistryRuntime } from './runtime'

type IComponentsRegistryAPI = IThunderboltRegistryAPI

export { IComponentsRegistryAPI }

/**
 * Components Registry for Thunderbolt Client Side Rendering
 */
export interface IComponentsRegistryCSR {
	initialize: () => Promise<void>
	isInitialized: () => boolean
	getRegistryAPI: () => IComponentsRegistryAPI
	/**
	 * Legacy API is used for migrating to the thunderbolt + registry integration
	 */
	getLegacyComponentsRegistrarAPI: () => IComponentsRegistrar
}

export function createComponentsRegistryCSR(
	options: {
		serviceTopology?: ServiceTopology
		url?: string
	} = {}
): IComponentsRegistryCSR {
	const { serviceTopology, url } = options

	let registryAPI: IComponentsRegistryAPI

	const withRequiredInitialization = () => {
		if (!registryAPI) {
			throw new Error('components is not initialized')
		}
	}

	const runtime = getGlobalRegistryRuntime<IInfoComponentModel>()

	if (!runtime) {
		console.info('Components Registry – fallback to client initialization')

		if (!serviceTopology) {
			throw new Error('Components Registry – serviceTopology or runtime is required to create registry')
		}
	}

	return {
		isInitialized() {
			return !!registryAPI
		},
		async initialize() {
			if (registryAPI) {
				throw new Error('components registry was already initialized')
			}

			registryAPI = await createRegistryAPI(registry, {
				/**
				 * using `lazy` registry for both SSR & CSR
				 *
				 * under the hood, it will use batch bundles,
				 * the rest components (that are not included at the batch), will be inlined in the head on SSR
				 * or lazy fetched on CSR
				 *
				 * Current issue – we don't recommend to use different modes on SSR and CSR
				 * because of the probability of the duplicated code in different bundles.
				 * Will be fixed in the next registry PRs
				 */
				mode: 'lazy',
				// libraries: getComponentsLibraries(serviceTopology),
				...(runtime
					? runtime
					: { libraries: serviceTopology ? getComponentsLibraries(serviceTopology, url) : [] }),
			})
		},

		getLegacyComponentsRegistrarAPI() {
			withRequiredInitialization()

			return {
				registerComponents: (hostAPI) => {
					registryAPI.forEachComponent((componentName, loader) => {
						/**
						 * TODO: hardcoded split should be removed after "specs.thunderbolt.componentsRegistry" experiment is merged
						 */
						const [name, uiType] = componentName.split('_')

						hostAPI.registerComponent(
							name,
							async () => {
								const model = await loader()

								return {
									default: model.component,
									component: model.component,
									controller: model.controller,
								}
							},
							uiType
						)
					})
				},
			}
		},

		getRegistryAPI() {
			withRequiredInitialization()

			return registryAPI
		},
	}
}
